<template>
  <got-blocked v-if="!allow">
    <v-alert type="info" :value="true" prominent text>
      I'm sorry you are unauthorized to view this page.
    </v-alert>
  </got-blocked>
  <v-container v-else fluid :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
    <v-row dense>
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-toolbar color="color1 color1Text--text" :dark="color1IsDark">
            <v-toolbar-title>
              {{ mGrid ? `${theme.name} ` : ''}}Players
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              :slot="$vuetify.breakpoint.smAndDown ? 'extension' : null"
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="filter.search"
              solo-inverted
              clearable
              @blur="saveState"
              :class="{'mb-3': $vuetify.breakpoint.smAndDown}"
            ></v-text-field>
            <v-btn color="color1Text" text icon @click.stop="dialog = true" v-if="user && user.isCollegeCoach">
              <v-icon>fas fa-filter</v-icon>
            </v-btn>
            <v-btn color="color1Text" text icon @click.stop="loadPlayers" :loading="loading">
              <v-icon>fas fa-sync-alt</v-icon>
            </v-btn>
          </v-toolbar>
          <loading-bar :value="loading"></loading-bar>
          <v-card-text class="pa-3">
            <v-row dense v-if="mGrid">
              <v-switch color="success" hide-details class="mr-3" label="Adults" v-model="filter.adults"></v-switch>
              <v-switch color="success" hide-details label="Juniors" v-model="filter.juniors"></v-switch>
            </v-row>
            <v-chip-group>
              <v-chip
                v-for="(filter, i) in allFilters"
                :key="i"
                close
                @click:close="dialog = true"
              >{{filter}}</v-chip>
            </v-chip-group>
            <player-list
              v-if="listView"
              :players="filteredPlayers"
              :loading="loading"
              :search="filter.search"
              :sort="sort"
            ></player-list>
            <player-cards-list
              v-else
              :players="filteredPlayers"
              :loading="loading"
              :search="filter.search"
              :sort="sort"
              @blocked="onBlocked"
              :infinite="true"
              @intersected="loadPlayers"
            ></player-cards-list>
            <div class="text-center" v-intersect="onIntersect">
              <v-btn
                color="color3"
                text
                v-if="!allLoaded"
                :loading="loading"
                @click.stop="loadPlayers"
              >load more</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Filter Dialog -->
    <v-dialog
      v-model="dialog"
      v-if="user && user.isCollegeCoach"
      fullscreen hide-overlay transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Filter Players</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="success white--text"
            fab small
            @click="dialog = false"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="Committed"
                :items="[
                  { text: 'Committed', value: 'Committed' },
                  { text: 'Uncommitted', value: 'Uncommitted' },
                  { text: 'Commit Unknown', value:'Commit Unknown' }
                ]"
                v-model="filter.committed"
                multiple
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="Graduation Year"
                :items="gradYears"
                v-model="filter.gradYear"
                multiple
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="Minimum Height"
                :items="heights"
                v-model="filter.height"
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-switch label="Has Videos" v-model="filter.videos" color="success"></v-switch>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="Dominant Hand"
                return-object
                :items="[
                  'Left',
                  'Right',
                  'Ambidextrous'
                ]"
                v-model="filter.dominantHand"
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="Side"
                :items="[
                  'Left',
                  'Right',
                  'Both'
                ]"
                v-model="filter.side"
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="Preferred Side"
                :items="[
                  'Left',
                  'Right',
                  'No Preference'
                ]"
                v-model="filter.sidePreferred"
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3"><!-- Block/D -->
              <v-select
                label="Blocker or Defender"
                :items="['Block', 'Defense', 'Split']"
                v-model="filter.defense"
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3"><!-- Block/D Pref -->
              <v-select
                label="Defensive Preference"
                :items="['Blocking', 'Defense', 'Spliting', 'No Preference']"
                v-model="filter.defensePreferred"
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="Minimum GPA"
                :items="gpas"
                v-model="filter.gpa"
                chips
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3">
              <v-select
                label="State"
                :items="states"
                v-model="filter.state"
                item-text="abbreviation"
                item-value="abbreviation"
                chips
                multiple
                deletable-chips
                color="color3"
                item-color="color3"
                attach
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- CARD/LIST FAB-->
    <v-tooltip left>
      <span>{{listView ? 'Card View' : 'List View'}}</span>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="color3 color3Text--text" fab fixed bottom right @click="toggleView">
          <v-icon>fas fa-{{listView ? 'table' : 'list'}}</v-icon>
        </v-btn>
      </template>
    </v-tooltip>
    <!-- Log in Dialog -->
    <got-blocked-dialog ref="blocked">
      <v-alert type="error" :value="true" text>
        {{blockedMsg}}
      </v-alert>
    </got-blocked-dialog>
    <v-snackbar
      v-model="noResults"
      vertical
    >
      We could not find anymore profiles
      <v-btn color="color3" @click.native="noResults = false" text>Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import PlayerProfile from '@/classes/PlayerProfile'
import PlayerList from '@/components/Player/PlayerList'
import PlayerCardsList from '@/components/Player/PlayerCardsList'
import debounce from 'lodash.debounce'
import { gradYears, heightInInches } from '@/classes/ProfileHelpers'
import Heights from '@/classes/Heights'
import GotBlockedDialog from '@/components/Utils/GotBlockedDialog'

export default {
  props: ['tournamentId'],
  data () {
    return {
      states: [],
      players: [],
      loading: false,
      dialog: false,
      listView: false,
      filter: {
        search: null,
        adults: true,
        juniors: true,
        committed: [],
        gradYear: [],
        videos: false,
        height: null,
        dominantHand: null,
        side: null,
        sidePreferred: null,
        defense: null,
        defensePreferred: null,
        gpa: null,
        state: [],
        ids: []
      },
      sort: {
        by: ['isJunior', 'hasPic', 'iGradYear', 'lastName', 'firstName'],
        desc: [true, true, false, false, false]
      },
      ages: [
        { text: '10U', value: '10U' },
        { text: '12U', value: '12U' },
        { text: '14U', value: '14U' },
        { text: '15U', value: '15U' },
        { text: '16U', value: '16U' },
        { text: '18U', value: '18U' },
        { text: '26U', value: '26U' },
        { text: 'N/A', value: '' }
      ],
      blockedMsg: null,
      heights: Heights,
      total: 0,
      lastFilter: null,
      noResults: false
    }
  },
  computed: {
    ...mapGetters(['color1IsDark', 'tournament', 'user', 'theme']),
    allFilters: {
      get () {
        const base = [...this.filter.committed, ...this.filter.gradYear, ...this.filter.state]
        this.filter.height && base.push(`${this.filter.height} & up`)
        this.filter.dominantHand && base.push(`Hand: ${this.filter.dominantHand}`)
        this.filter.side && base.push(`Side: ${this.filter.side}`)
        this.filter.sidePreferred && base.push(`Preferred Side: ${this.filter.sidePreferred}`)
        this.filter.defense && base.push(`Defense: ${this.filter.defense}`)
        this.filter.defensePreferred && base.push(`Preferred Defense: ${this.filter.defensePreferred}`)
        this.filter.gpa && base.push(`Minimum GPA: ${this.filter.gpa}`)
        return base
      }
    },
    allow () {
      return (this.user && this.user.canSearchPlayers) || this.$route.name === 'tournament-players' || this.mGrid
    },
    mGrid () {
      return this.$route.name === 'player-search' && this.theme.props && this.theme.props.includes('member-grid')
    },
    playersWPic () {
      return this.players.filter(f => f.hasPic)
    },
    playersWDH () {
      return this.players.filter(f => f.metrics && f.metrics.dominantHand)
    },
    filteredPlayers () {
      let p = this.players
      const x = this.filter
      const a = x.committed
      const g = x.gradYear
      if (!x.adults) {
        p = p.filter(f => f.isJunior)
      }
      if (!x.juniors) {
        p = p.filter(f => !f.isJunior)
      }
      if (a.length) {
        p = p.filter(f => a.includes(f.commitTag))
      }
      if (g.length) {
        p = p.filter(f => g.includes(f.gradYear))
      }
      if (x.videos) {
        p = p.filter(f => f.videos > 0)
      }
      if (x.height) {
        const i = heightInInches(x.height)
        p = p.filter(f => f.inches >= i)
      }
      if (x.dominantHand) {
        p = p.filter(f => f.metrics && f.metrics.dominantHand === x.dominantHand)
      }
      if (x.side) {
        p = p.filter(f => f.metrics && f.metrics.side === x.side)
      }
      if (x.sidePreferred) {
        p = p.filter(f => f.metrics && f.metrics.sidePreferred === x.sidePreferred)
      }
      if (x.defense) {
        p = p.filter(f => f.metrics && f.metrics.defense === x.defense)
      }
      if (x.defensePreferred) {
        p = p.filter(f => f.metrics && f.metrics.defensePreferred === x.defensePreferred)
      }
      if (x.state.length) {
        p = p.filter(f => x.state.includes(f.state))
      }
      if (x.gpa) {
        p = p.filter(f => f.gpa && f.gpa >= x.gpa)
      }

      return p
    },
    gradYears () {
      return gradYears
    },
    gpas () {
      var result = []
      for (var i = 10; i < 51; i++) {
        result.push((i / 10).toFixed(1))
      }
      return result
    },
    allLoaded () {
      return this.isTournament && this.tournament && this.tournament.activePlayersNoCoach.length === this.players.length
    },
    isTournament () {
      return this.$route.name === 'tournament-players'
    }
  },
  methods: {
    clear (f) {
      if (f.startsWith(this.filter.height)) {
        this.filter.height = null
        return
      }
      if (f.startsWith('Hand:')) {
        this.filter.dominantHand = null
        return
      }
      if (f.startsWith('Side:')) {
        this.filter.side = null
        return
      }
      if (f.startsWith('Preferred Side:')) {
        this.filter.sidePreferred = null
        return
      }
      if (f.startsWith('Defense:')) {
        this.filter.defense = null
        return
      }
      if (f.startsWith('Preferred Defense:')) {
        this.filter.defensePreferred = null
        return
      }
      for (const prop in this.filter) {
        this.filter[prop] = this.filter[prop].filter(a => a !== f)
      }
      if (this.filter.states.includes(f)) {
        this.filter.states = this.filter.states.filter(a => a !== f)
      }
    },
    loadPlayers: debounce(function () {
      this.getPlayers()
    }, 333),
    getPlayers () {
      if (this.allLoaded) return
      this.loading = true
      const q = this.isTournament ? `?tournamentId=${this.tournamentId}` : null
      const limit = this.isTournament ? this.tournament.activePlayersNoCoach.map(m => m.ppId) : false
      const f = JSON.parse(JSON.stringify(this.filter))
      var a = JSON.stringify(this.filter)
      var b = JSON.stringify(this.lastFilter)
      const newSearch = this.lastFilter === null || a !== b
      f.currentItemCount = newSearch ? 0 : this.players.length
      console.log(f)

      this.$VBL.get.playerList(q, f)
        .then(r => {
          this.total = r.data.totalItems
          this.lastFilter = r.data.filter
          delete this.lastFilter.currentItemCount
          delete this.lastFilter.pageSize
          const data = limit ? r.data.players.filter(f => limit.includes(f.id)) : r.data.players
          var pushed = 0
          data.forEach(m => {
            var x = this.players.find(f => f.id === m.id)
            if (!x) {
              this.players.push(new PlayerProfile(null, m))
              pushed++
            }
          })
          if (!this.allLoaded) this.noResults = pushed === 0
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    saveState () {
      const query = {}
      const s = this.filter.search
      Object.assign(query, this.$route.query)
      if (!s && query.s) {
        delete query.s
      }
      if (s && s !== query.s) {
        query.s = s
      }
      if (!this.listView && query.v) {
        delete query.v
      } else if (this.listView) {
        query.v = 'list'
      }

      this.$router.push({
        path: this.$route.path,
        query
      }).catch(e => {})
    },
    setState (q) {
      if (!q) return
      this.filter.search = q && q.s ? q.s : null
      this.listView = q.v && q.v === 'list'
    },
    onSearch: debounce(function () {
      this.saveState()
    }, 500),
    toggleView () {
      this.listView = !this.listView
      this.saveState()
    },
    onBlocked (msg) {
      this.blockedMsg = msg
      this.$refs && this.$refs.blocked && this.$refs.blocked.open()
    },
    onIntersect (entries, observer) {
      if (this.loading) return
      if (entries[0].isIntersecting) this.loadPlayers()
    },
    getStates () {
      if (this.states.length) return
      this.loading = true
      this.$http.get(`${window.location.origin}/static/json/states.json`)
        .then(r => {
          this.states = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    'filter.search': 'onSearch',
    'filter.age': function (n, o) {
      if (n.length === 0) {
        this.filter.age.push(null)
      }
    }
  },
  mounted () {
    this.setState(this.$route.query)
    this.getStates()
    this.loadPlayers()
  },
  components: {
    PlayerList,
    PlayerCardsList,
    GotBlockedDialog
  }
}
</script>

<style scoped>

</style>
