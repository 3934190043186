<template>
  <v-data-table
    :headers="heads"
    :items="players"
    :options.sync="pagination"
    :loading="loading"
    loading-text="Loading Players..."
    :search="search"
    item-key="id"
    :footer-props="{itemsPerPageOptions: [25, 50, 100, -1]}"
  >
    <template v-slot:progress>
      <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
    </template>
    <template v-slot:[`item.gradYear`]="{item}">
      {{item.isJunior && item.gradYear ? item.gradYear : 'N/A'}}
    </template>
    <template v-slot:[`item.club`]="{item}">
      {{item.isJunior && item.club ? item.club : 'N/A'}}
    </template>
    <template v-slot:[`item.age`]="{item}">
      {{item.ageDivision || 'N/A'}}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip left v-if="!$vuetify.breakpoint.xsOnly">
        <span>View {{item.firstName}}'s profile</span>
        <template v-slot:activator="{on}">
          <v-btn
            color="color3 color3Text--text"
            small
            fab
            v-on="on"
            :to="{
              name: 'player-profile',
              params: {
                id: item.id,
                playerProfileId: item.id,
                name: `${item.firstName} ${item.lastName}`,
                profilePic: item.profilePic
              }
            }"
          >
            <v-icon small>fas fa-caret-right</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
      <v-btn
        color="color3 color3Text--text"
        small
        text
        v-else
        :to="{
          name: 'player-profile',
          params: {
            id: item.id,
            playerProfileId: item.id,
            name: `${item.firstName} ${item.lastName}`,
            profilePic: item.profilePic
          }
        }"
      >
        View {{item.firstName}}'s profile <v-icon class="ml-3">fas fa-caret-right</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['players', 'search', 'loading'],
  data () {
    return {
      listView: false,
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'City', value: 'city' },
        { text: 'State', value: 'state' },
        { text: 'Club', value: 'club' },
        { text: 'Grad Year', value: 'gradYear' },
        { text: 'Age', value: 'age' },
        { text: 'Videos', value: 'videos' },
        { text: '', value: 'actions' }
      ],
      pagination: {}
    }
  },
  computed: {
    ...mapGetters(['user']),
    heads () {
      var h = this.user && this.user.vbl ? [...this.headers, { text: 'id', value: 'id' }] : this.headers
      if (!(this.user && this.user.isCollegeCoach)) h = h.filter(f => f.value !== 'videos')
      return h
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
