var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.heads,"items":_vm.players,"options":_vm.pagination,"loading":_vm.loading,"loading-text":"Loading Players...","search":_vm.search,"item-key":"id","footer-props":{itemsPerPageOptions: [25, 50, 100, -1]}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":true,"color":"color3"}})]},proxy:true},{key:"item.gradYear",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isJunior && item.gradYear ? item.gradYear : 'N/A')+" ")]}},{key:"item.club",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isJunior && item.club ? item.club : 'N/A')+" ")]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ageDivision || 'N/A')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"color3 color3Text--text","small":"","fab":"","to":{
            name: 'player-profile',
            params: {
              id: item.id,
              playerProfileId: item.id,
              name: ((item.firstName) + " " + (item.lastName)),
              profilePic: item.profilePic
            }
          }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-caret-right")])],1)]}}],null,true)},[_c('span',[_vm._v("View "+_vm._s(item.firstName)+"'s profile")])]):_c('v-btn',{attrs:{"color":"color3 color3Text--text","small":"","text":"","to":{
        name: 'player-profile',
        params: {
          id: item.id,
          playerProfileId: item.id,
          name: ((item.firstName) + " " + (item.lastName)),
          profilePic: item.profilePic
        }
      }}},[_vm._v(" View "+_vm._s(item.firstName)+"'s profile "),_c('v-icon',{staticClass:"ml-3"},[_vm._v("fas fa-caret-right")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }